import logo from './logo.svg';
import shramp from './shramp.png';
import './App.css';
import 'particles.js/particles';
import * as React from 'react'
import particlesJS from 'particles.js'

function App() {

  React.useEffect(() => {
    window.particlesJS.load('particles-js', '/particles.json', function() {
      console.log('callback - particles.js config loaded');
    });
  }, []);

  return (
    <div class="container">
    <div id="particles-js" style={{ width: '100%', height: '100vh' }} />
    <div className="App">
      <header className="App-header">
        <img src={shramp} className="App-logo" alt="logo" />
        <h1>have you ever seen a miracle?</h1>
      </header>
    </div>
    </div>
  );
}

export default App;
